import React from "react";
import Header from "../../components/header/header";
import "../../sass/main.scss";
import "./landing.scss";

const LandingHeaderButton = () => {
  return (
    <>
      <a type="button" className="btn yc-global-header__navigation__btn main">
        Early Access
      </a>
    </>
  );
};

const Landing = () => {
  return (
    <main className="yc-landing">
      <section className="yc-landing__banner">
        <div className="grained" id="grain-wrap"></div>
        <div className="background-glow">
          <div className="background-glow__wrap">
            <div className="background-glow__one"></div>
            <div className="background-glow__two"></div>
            <div className="background-glow__three"></div>
          </div>
        </div>
        <Header navtButton={<LandingHeaderButton></LandingHeaderButton>}>
        </Header>

        <div className="yc-landing__banner__manifest">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="yc-landing__banner__manifest__text">
                  <h2 className="animate__animated animate__fadeInUp animate__delay-100ms">
                    Fractionalised ownership<br className="d-none d-lg-flex" />{" "}
                     <span> on the blockchain</span>{" "}
                  </h2>
                  <p className="animate__animated animate__fadeInUp animate__delay-900ms">
                    Ownshare is a protocol that powers asset tokenization,
                    hospitality NFTs, an asset marketplace, and a referral
                    program built on the blockchain.{" "}
                  </p>
                  {/* <a href="" className="btn">Join Waitlist</a> */}
                  <div className="yc-landing__banner__manifest__buttons">
                    <a href="" className="btn landing-secondary d-none d-md-flex">Coming Soon</a>
                    <ul className="d-md-none">
                      <a
                      href="https://discord.gg/ZfDk7jQjBh"
                      className="btn btn-social mt-5"
                      // rel="noopener noreferrer"
                    >
                      <i className="fab fa-discord"></i> Discord 
                    </a>
                    <a
                      href="https://twitter.com/ownshare_io"
                      className="btn btn-social mt-5"
                      // rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i> Twitter
                    </a>
                    <a
                      href="https://instagram.com/ownshare_io"
                      // href="/#waitlist"
                      className="btn btn-social mt-5"
                    >
                      <i className="fab fa-instagram"></i> Instagram
                    </a>
                    </ul>
                    {/* <a
                      href="https://docs.google.com/document/d/1QhRFrWmn_9-x9gw1b2ZDr9tlFs_6mxUxk0otmwkMJdA/preview"
                      // href="/#waitlist"
                      target="_blank"
                      className="btn me-4"
                      rel="noopener noreferrer"
                    >
                      List assets
                    </a>
                    <a
                      // href="https://www.netlify.com/products/forms/"
                      href="/#waitlist"
                      // target="_blank"
                      className="btn landing-secondary"
                    >
                      Join Community
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="yc-landing__background-grid__overlay"></div> */}
        </div>
        {/* <img src={Hills} alt="" className="banner-hills" /> */}
      </section>

      {/* <Footer /> */}
    </main>
  );
};

export default Landing;














// import React from "react";
// import Header from "../../components/header/header";
// import Footer from "../../components/footer/footer";
// // import Hills from "./images/hills-alt.svg";
// import HillsAlt from "./images/hills-alternate.svg";
// import CtaHillsA from "./images/hills.svg";
// import CtaHillsB from "./images/cta-before.svg";
// import PictureOne from "./images/Performance.svg";
// import PictureTwo from "./images/Align.svg";
// import PictureThree from "./images/refer.svg";
// import CtaImage from "./images/cta-placeholder.png"
// import GlobeSideOne from "./images/globe-alt-side-1.png";
// import GlobeSideTwo from "./images/globe-alt-side-2.png";
// import GlobeSideThree from "./images/globe-alt-side-3.png";
// import GlobeSideFour from "./images/globe-alt-side-4.png";
// import CoinToken from "./images/coin.svg";
// import CoinMarker from "./images/coin-mark.svg";
// import ChartMark from "./images/chart-mark.svg";
// import ShareMark from "./images/share.svg";
// import StackMark from "./images/stack.svg";
// import BarMark from "./images/bar.svg";
// import TransMark from "./images/trans.svg";
// import "../../sass/main.scss";
// import "./landing.scss";

// const LandingHeaderLinks = () => {
//   return (
//     <ul className="nav yc-global-header__navigation__nav">
//       <li className="yc-global-header__navigation__link">
//         <a href="#">Home</a>
//       </li>
//       <li className="yc-global-header__navigation__link">
//         <a href="#">Explore Assets</a>
//       </li>
//       <li className="yc-global-header__navigation__link">
//         <a href="#">List</a>
//       </li>
//       <li className="yc-global-header__navigation__link">
//         <a href="#">Resources</a>
//       </li>
//       <li className="yc-global-header__navigation__link">
//         <a href="#">Community</a>
//       </li>
//     </ul>
//   );
// };

// const CheckIcon = () => {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 38 38"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
//         fill="var(--theme-primary)"
//         opacity=".2"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M27.0972 11.709L15.7685 22.6578L12.776 19.4278C12.4929 19.1808 12.1346 19.037 11.7594 19.0198C11.3842 19.0025 11.0142 19.1129 10.7097 19.3328C10.4151 19.5397 10.2081 19.849 10.1291 20.2002C10.0501 20.5515 10.1046 20.9196 10.2822 21.2328L13.8447 27.0515C14.201 27.574 14.8185 27.9065 15.4835 27.9065C16.1485 27.9065 16.7422 27.574 17.0985 27.0515C17.6685 26.3153 28.546 13.324 28.546 13.324C29.971 11.899 28.2372 10.5928 27.121 11.709H27.0972Z"
//         fill="var(--theme-primary)"
//         opacity="1"
//       />
//     </svg>
//   );
// };

// const LandingHeaderButton = () => {
//   return (
//     <>
//       <a type="button" className="btn yc-global-header__navigation__btn main">
//         Early Access
//       </a>
//     </>
//   );
// };

// const Landing = () => {
//   return (
//     <main className="yc-landing">
//       <section className="yc-landing__banner">
//         <div className="grained" id="grain-wrap"></div>
//         <div className="background-glow">
//           <div className="background-glow__wrap">
//             <div className="background-glow__one"></div>
//             <div className="background-glow__two"></div>
//             <div className="background-glow__three"></div>
//           </div>
//         </div>
//         <Header navtButton={<LandingHeaderButton></LandingHeaderButton>}>
//           <LandingHeaderLinks />
//         </Header>

//         <div className="yc-landing__banner__manifest">
//           <div className="container-xl">
//             <div className="row">
//               <div className="col-12 col-md-7">
//                 <div className="yc-landing__banner__manifest__text">
//                   <h2 className="animate__animated animate__fadeInUp animate__delay-100ms">
//                     Fractionalised <br className="d-none d-lg-flex" />{" "}
//                      <span> ownership </span>{" "}
                    
//                     <span>on the blockchain</span>{" "}
//                   </h2>
//                   <p className="animate__animated animate__fadeInUp animate__delay-900ms">
//                     Ownshare is a protocol that powers asset tokenization,
//                     hospitality NFTs, an asset marketplace, and a referral
//                     program built on the blockchain.{" "}
//                   </p>
//                   {/* <a href="" className="btn">Join Waitlist</a> */}
//                   <div className="yc-landing__banner__manifest__buttons d-flex">
//                     <a
//                       href="https://docs.google.com/document/d/1QhRFrWmn_9-x9gw1b2ZDr9tlFs_6mxUxk0otmwkMJdA/preview"
//                       // href="/#waitlist"
//                       target="_blank"
//                       className="btn me-4"
//                       rel="noopener noreferrer"
//                     >
//                       List assets
//                     </a>
//                     <a
//                       // href="https://www.netlify.com/products/forms/"
//                       href="/#waitlist"
//                       // target="_blank"
//                       className="btn landing-secondary"
//                     >
//                       Join Community
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="d-none d-md-flex col-md-5">
//                 <div className="yc-landing__banner__manifest__illustration d-none d-md-flex">
//                   <img src={GlobeSideOne} alt="" className="globe-side globe-side-one animate__animated animate__fadeInUp" />
//                   <img src={GlobeSideTwo} alt="" className="globe-side globe-side-two animate__animated animate__animated animate__fadeInUp" />
//                   <img src={GlobeSideThree} alt="" className="globe-side globe-side-three animate__animated animate__animated animate__fadeInUp" />
//                   <img src={GlobeSideFour} alt="" className="globe-side globe-side-four animate__animated animate__animated animate__fadeInUp" />
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* <div className="yc-landing__background-grid__overlay"></div> */}
//         </div>
//         {/* <img src={Hills} alt="" className="banner-hills" /> */}
//       </section>

//       <section className="yc-landing__features">
//         <img src={HillsAlt} alt="" className="banner-hills" />
//         <div className="yc-landing__features__header">
//           <div className="container">
//             <div className="yc-landing__features__header__text">
//               <h6>
//                 <span className="animate__animated animate__fadeInUp animate__delay-1s">
//                   A <span> Better </span> way to own <span> Real Assets,</span>{" "}
//                 </span>{" "}
//               </h6>
//               <h4>Share and earn from asset rewards</h4>
//               <p>
//                 You can buy and own a fraction of real assets, with potential to
//                 earn from their revenue and can also trade and share access to
//                 these assets to earn more rewards.{" "}
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="yc-landing__features__grid" data-aos="fade-up"
//         data-aos-delay="100">
//           <div className="container">
//             <div className="row">
//               <div className="col">
//                 <div className="yc-landing__features__grid__items">
//                   <div className="yc-landing__features__grid__items__block yc-landing__features__grid__items__block__first">
//                     <div className="yc-landing__features__grid__items__block__header">
//                       <div className="">
//                         <img src={CoinToken} alt="" />
//                         <h5>Earn Rewards</h5>
//                       </div>
//                       <h6>
//                         Earn potentially better returns, and more stable yields
//                         by investing into asset backed tokens.{" "}
//                       </h6>
//                       <a>Get Started</a>
//                     </div>
//                     <div className="yc-landing__features__grid__items__block__image first">
//                       <picture>
//                         <img src={PictureOne} alt="" />
//                       </picture>
//                     </div>
//                   </div>
//                   <div className="yc-landing__features__grid__items__block yc-landing__features__grid__items__block__second">
//                     <div className="yc-landing__features__grid__items__block__header">
//                       <div className="">
//                         <img src={CoinToken} alt="" />
//                         <h5>Shared Ownership</h5>
//                       </div>
//                       <h6>
//                         A simple platform for asset owners to share ownership of their assets{" "}
//                       </h6>
//                       <a>Get Started</a>
//                     </div>
//                     <div className="yc-landing__features__grid__items__block__image second">
//                       <picture>
//                         <img src={PictureTwo} alt="" />
//                       </picture>
//                     </div>
//                   </div>
//                   <div className="yc-landing__features__grid__items__block yc-landing__features__grid__items__block__third">
//                     <div className="yc-landing__features__grid__items__block__header">
//                       <div className="">
//                         <img src={CoinToken} alt="" />
//                         <h5>Tokenize</h5>
//                       </div>
//                       <h6>
//                         Tokenized referral earning programme.{" "}
//                       </h6><a>Get Started</a>
//                     </div>
//                     <div className="yc-landing__features__grid__items__block__image third">
//                       <picture>
//                         <img src={PictureThree} alt="" />
//                       </picture>
//                     </div>
//                   </div>
//                   {/* <div className="yc-landing__features__grid__items__block yc-landing__features__grid__items__block__fourth">
//                     <div className="yc-landing__features__grid__items__block__header">
//                       <div className="">
//                         <img src={CoinToken} alt="" />
//                         <h5>Marketplace to trade assets</h5>
//                       </div>
//                       <h6>
//                         Earn potentially better returns, and more stable yields
//                         wby investing into asset backed tokens.{" "}
//                       </h6>
//                       <a href="">See More</a>
//                     </div>
//                     <div className="yc-landing__features__grid__items__block__image fourth">
//                       <picture>
//                         <img src={PictureTwo} alt="" />
//                       </picture>
//                     </div>
//                   </div> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="yc-landing__cta">
//         <img
//           src={CtaHillsB}
//           alt=""
//           className="yc-landing__cta__bg yc-landing__cta__bg-before"
//         />
//         <div className="container">
//           <div className="row">
//             <div className="col-md-7">
//               <div className="yc-landing__cta__text">
//                 <p>Get Started</p>
//                 <h2>Join our growing portfolio of iconic assets </h2>
//               </div>
//               <div className="yc-landing__cta__button">
//                 <a
//                   href="https://docs.google.com/document/d/1QhRFrWmn_9-x9gw1b2ZDr9tlFs_6mxUxk0otmwkMJdA/preview"
//                   // href="/#waitlist"
//                   target="_blank"
//                   className="btn me-4"
//                   rel="noopener noreferrer"
//                 >
//                   LitePaper
//                 </a>
//                 <a
//                   // href="https://www.netlify.com/products/forms/"
//                   href="/#waitlist"
//                   // target="_blank"
//                   className="btn secondary"
//                 >
//                   Join Waitlist
//                 </a>
//               </div>
//             </div>
//             <div className="col-md-5">
//               <div className="yc-landing__cta__img">
//                 <img src={CtaImage} alt="" />
//               </div>
//             </div>
//           </div>
//         </div>
//         <img
//           src={CtaHillsA}
//           alt=""
//           className="yc-landing__cta__bg yc-landing__cta__bg-after"
//         />
//       </section>

//       <section className="yc-landing__about" data-aos="fade-up"
//         data-aos-delay="100">
//         <div className="yc-landing__about__header">
//           <div className="container">
//             <div className="row">
//               <div className="col">
//                 <div className="yc-landing__about__header__text">
//                   <h6>Get Started</h6>
//                   <h2>Join the simplified assets platform </h2>
//                   <p>
//                      A simple platform for asset owners to share ownership of their assets and share revenue with co-owners with a marketplace for owners to exchange these assets. 
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="yc-landing__about__list">
//           <div className="container">
//             <div className="row">
//               <div className="col-12">
//                 <div className="yc-landing__about__list__heading">
//                   <h3>Investors</h3>
//                 </div>
//               </div>
//               <div className="col-md-6 col-lg-4">
//                 <div className="yc-landing__about__list__item">
//                   <img src={ChartMark} alt="" />
//                   <h2>Fractionalized Investment Opportunity:</h2>
//                   <p>
//                     <CheckIcon/> Tokenization of Physical assets using NFTs
//                   </p>
//                   <p>
//                     <CheckIcon/> Digital Trusted Marketplace
//                   </p>
//                   {/* <a href="">Get started</a> */}
//                 </div>
//               </div>
//               <div className="col-md-6 col-lg-4">
//                 <div className="yc-landing__about__list__item">
//                   <img src={CoinMarker} alt="" />
//                   <h2>Simplified Investment Protocols:</h2>
//                   <p>
//                     <CheckIcon/> Smart Contracts on the Blockchain
//                   </p>
//                   <p>
//                     <CheckIcon/> Instant KYC Process
//                   </p>
//                   {/* <a href="">Get started</a> */}
//                 </div>
//               </div>
//               <div className="col-md-6 col-lg-4">
//                 <div className="yc-landing__about__list__item">
//                   <img src={TransMark} alt="" style={{height:'48px'}}/>
//                   <h2>Attractive Revenue Streams:</h2>
//                   <p>
//                     <CheckIcon/> Tokenized referral earning program
//                   </p>
//                   <p>
//                     <CheckIcon/> Easy Transfer/Re-sale of Asset
//                   </p>
//                   {/* <a href="">Get started</a> */}
//                 </div>
//               </div>
//               <div className="col-12">
//                 <div className="yc-landing__about__list__heading">
//                   <h3>Assets Owners</h3>
//                 </div>
//               </div>
//               <div className="col-md-6 col-lg-4">
//                 <div className="yc-landing__about__list__item">
//                   <img src={BarMark} alt="" />
//                   <h2>Easy Access to Capital: </h2>
//                   <p><CheckIcon/> Unlimited number of potential Investors</p>
//                   <p><CheckIcon/> Royalties on Sales</p>
//                 </div>
//               </div>
//               <div className="col-md-6 col-lg-4">
//                 <div className="yc-landing__about__list__item">
//                   <img src={StackMark} alt="" />
//                   <h2>Modern Sales Channels:</h2>
//                   <p><CheckIcon/> Increased Awareness</p>
//                   <p><CheckIcon/> Global Marketplace</p>
//                 </div>
//               </div>

//               <div className="col-md-6 col-lg-4">
//                 <div className="yc-landing__about__list__item">
//                   <img src={ShareMark} alt="" />
//                   <h2>Simplified Distribution of Earnings:</h2>
//                   <p><CheckIcon/> Instant Crypto Currency Transactions </p>
//                   <p><CheckIcon/> Low Transfer Fees</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Footer />
//     </main>
//   );
// };

// export default Landing;
