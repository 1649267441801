import React from "react";
import Logo from "../images/logo-alt.svg";
import "./header.scss";

function Header({ navtButton, children }) {
  return (
    <header className="yc-global-header">
      <div className="container-xl">
        <div className="yc-global-header__navigation">
          <nav className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
            <a
              href="/"
              className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
            >
              <img src={Logo} alt="" />
            </a>

            <div className="col-12 col-md-auto mb-2 justify-content-center mb-md-0">
              {children}
            </div>

            <div className="yc-global-header__navigation__socials d-none d-md-flex col-md-5 justify-content-end align-items-center">
              <ul className="nav">
                <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="bhde_io">
                  <a className="nav-link" target="_blank" href="https://twitter.com/ownshare_io"
                    rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                </li>

               <li className="nav-item">
                  <a className="nav-link" target="_blank" href="https://discord.gg/ZfDk7jQjBh"
                    rel="noopener noreferrer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="ownshare"><i className="fab fa-discord"></i></a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" target="_blank" href="https://instagram.com/ownshare_io"
                    rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                </li>

                {/* <li className="nav-item">
                  <a className="nav-link" target="_blank" href=""
                    rel="noopener noreferrer"><i className="fab fa-telegram" data-bs-toggle="tooltip" data-bs-placement="bottom" title="bhde_io"></i></a>
                </li> */}
              </ul>
              {navtButton}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
