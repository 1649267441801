import React from "react";
import Routes from './router';

const App = () => {
  return (
    <Routes />
  );
}

export default App;
